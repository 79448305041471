import "./styles.scss";
//import TypeIt from "typeit-react";
import { motion } from "framer-motion";
import AnimatedText from "./AnimatedText";
import { Bubbles } from "./Bubbles";
import arrow from "../../assets/arrow-down.png";
import { ScrollToId } from "../ScrollToId";

const buttonMotion = {
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            delay: 0.8,
            duration: 0.5,
            ease: [0.455, 0.03, 0.515, 0.955],
            type: "spring",
            stiffness: 80,
        },
    },
    hidden: { y: 35, opacity: 0 },
};

const textMotion = {
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            delay: 0.5,
            duration: 0.7,
            ease: [0.455, 0.03, 0.515, 0.955],
            type: "spring",
            stiffness: 80,
        },
    },
    hidden: { x: 150, opacity: 0 },
};

export const Start = () => {
    
    const headerText = [{ type: "heading1", text: "Philip Robinson" }];

    const headerContainer = {
        visible: {
            transition: {
                staggerChildren: 0.025,
            },
        },
    };

    return (
        <div id="start-content">
            <div className="header">
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={headerContainer}
                >
                    {headerText.map((item, index) => {
                        return <AnimatedText {...item} key={index} />;
                    })}
                </motion.div>
                <motion.div
                    className="header-text"
                    variants={textMotion}
                    initial="hidden"
                    animate="visible"
                >
                    <p>En frontend-utvecklare med passion för webbdesign.</p>
                </motion.div>


            </div>

            <motion.div
                className="scroll"
                animate={{ opacity: 1, y: "0" }}
                transition={{
                    duration: 0.4,
                    delay: 1.4,
                    ease: [0.455, 0.03, 0.515, 0.955],
                    type: "spring",
                    stiffness: 150,
                }}
            >
                <p style={{fontSize: "20px"}}>Under development</p>
                
            </motion.div>

            <Bubbles />

            {/*<h1>
                Hej! &#123;
                <br />
                <TypeIt
                    style={{ paddingLeft: "80px" }}
                    options={{
                        speed: 80,
                        waitUntilVisible: true,
                    }}
                >
                    frontend: <SuperStrong>true</SuperStrong>;
                </TypeIt>
                <br /> &#125;
            </h1>*/}
        </div>
    );
};


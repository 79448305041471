import upArrow from "../assets/up-arrow.png"
import { useState, useEffect } from "react";

export const ToTop = () => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("scroll", onScroll, { passive: true });
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    return (
        <div
            onClick={() => scrollToTop()}
            style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                width: "55px",
                height: "55px",
                cursor: "pointer",
                opacity: offset > 300 ? "1" : "0",
                transition: "ease 0.6s opacity"
            }}
        >
            <img src={upArrow} alt="scroll" style={{ width: "100%" }} />
        </div>
    );
};

import React, { useState } from "react";
import { motion } from "framer-motion";

// Word wrapper
const Wrapper = (props) => {
    // We'll do this to prevent wrapping of words using CSS
    return <span className="word-wrapper">{props.children}</span>;
};

// Map API "type" vaules to JSX tag names
const tagMap = {
    paragraph: "p",
    heading1: "h1",
    heading2: "h2",
};

const item = {
    hidden: {
        y: "80%",
        transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
        y: 0,
        transition: {
            ease: [0.455, 0.03, 0.515, 0.955],
            type: "spring",
            stiffness: 100,
            duration: 0.75,
        },
    },
};

const Text = props => {
    // For controlling animated class
    const [animated, setAnimated] = useState(false);
    return (
        <motion.span
            style={{ display: "inline-block" }}
            variants={item}
            className={animated ? "animated" : ""}
            onMouseEnter={() => setAnimated(() => true)}
            onAnimationEnd={() => setAnimated(() => false)}
        >
            {props.children}
        </motion.span>
    );
}

const AnimatedCharacters = (props) => {
    //  Split each word of props.text into an array
    const splitWords = props.text.split(" ");

    // Create storage array
    const words = [];

    // Push each word into words array
    for (const [, item] of splitWords.entries()) {
        words.push(item.split(""));
    }

    // Add a space ("\u00A0") to the end of each word
    words.map((word) => {
        return word.push("\u00A0");
    });

    // Get the tag name from tagMap
    const Tag = tagMap[props.type];

    return (
        <Tag>
            {words.map((word, index) => {
                return (
                    // Wrap each word in the Wrapper component
                    <Wrapper key={index}>
                        {words[index].flat().map((element, index) => {
                            return (
                                <span
                                    className="letter"
                                    style={{
                                        overflow: "hidden",
                                        display: "inline-block",
                                    }}
                                    key={index}
                                >
                                    <motion.span
                                        style={{ display: "inline-block" }}
                                        variants={item}
                                    >
                                        <Text>{element}</Text>
                                    </motion.span>
                                </span>
                            );
                        })}
                    </Wrapper>
                );
            })}
        </Tag>
    );
};

export default AnimatedCharacters;

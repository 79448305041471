import Canvas from "./components/canvas";
//import { Menu } from "./components/Menu";
import { Start } from "./components/Start";
//import { About } from "./components/About";
//import { Contact } from "./components/Contact";
import { ToTop } from "./components/ToTop";
//import { useEffect } from "react";

export const App = () => {

    /*useEffect(() => {
        window.setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100)
    }, [])*/

    return (
        <div>
            {/* <Menu /> */}
            <a
                style={{
                    color: "#fff",
                    textDecoration: "underline",
                    position: "absolute",
                    top: window.innerWidth > 768 ? "30px" : "20px",
                    right: window.innerWidth > 768 ? "50px" : "20px",
                }}
                href="mail@philiprobinson.se"
            >
                mail@philiprobinson.se
            </a>

            <Start />

            {/*<About />

            <Contact />*/}

            <Canvas></Canvas>

            <ToTop />
        </div>
    );
};
